import ROUTES from 'config/routes';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

type ThemeMode = 'light' | 'dark';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useDarkMode = () => {
  const router = useRouter();
  const [theme, setTheme] = useState<ThemeMode>(() => {
    if (router.pathname.startsWith(ROUTES.TEAM)) {
      return 'dark';
    } 
      return 'light';
  });

  const setMode = (mode: ThemeMode) => {
    window.localStorage.setItem('theme', mode);
    setTheme(mode);
  };

  const themeToggler = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    theme === 'light' ? setMode('dark') : setMode('light');
  };

  useEffect(() => {
    if (router.pathname.startsWith(ROUTES.TEAM)) {
      setTheme('dark');
    } else {
      setTheme('light');
    }
  }, [router.pathname]);

  // useEffect(() => {
  //   const localTheme = window.localStorage.getItem('theme');
  //   localTheme && setTheme(localTheme as ThemeMode);
  // }, []); // TODO: use after adding theme toggler
  return { theme, themeToggler, setMode };
};
