const ROUTES = {
  HOME: '/',
  PANEL: '/panel',
  LOG_IN: '/login',
  LOG_OUT: '/panel/logout',
  REGISTER: '/register',
  REGISTER_ACCOUNT: '/register/account',
  REGISTER_ACCOUNT_SUCCESS: '/register/success',
  WELCOME_PAGE: '/welcome',
  RESET: '/reset',
  CHANGE_PASSWORD: '/resetting/reset/:token',
  EDIT_REVIEW_PAGE: '/invitation/:slug/edit/:token',
  EDIT_NPS_PAGE: '/nps/invitation/:token',
  REVIEW_CONFIRM: '/reviews/:slug/confirm/:token',
  SURVEYS: {
    PRODUCTS_REVIEW: '/invitation/:token/product/:productId/grade/:grade',
    INVITATION: '/surveys/invitation/:invitationToken',
    ANONYMOUS: '/surveys/anonymous/:surveyToken',
    PREVIEW: '/surveys/preview/:surveyToken',
    FORM: '/surveys/form/:formToken',
  },
  THANK_YOU_PAGE: '/invitation/thank-you',
  OPT_OUT_CONFIRMATION_PAGE: '/invitation/optout/:slug/:invitationToken',
  MEDIATION: '/mediation/:token',
  THANK_YOU_PAGE_PREVIEW: '/panel/api/invitation_config/:configId/thank_you_page/preview',
  PRICING: '/pricing',
  REFERENCES: '/references',
  USER_HOME_PAGE: '/find-company',
  JOBS_BOARD: '/jobs',
  CATEGORY_PAGE: '/categories',
  CATEGORIES: {
    MAIN: '/categories',
    SHOPS: '/categories/shops',
    SERVICES: '/categories/services',
    HOTELS: '/categories/hotels',
    RESTAURANTS: '/categories/restaurants',
    SHOPS_CATEGORY: '/categories/shops/:category',
    SERVICES_CATEGORY: '/categories/services/:category',
    HOTELS_CATEGORY: '/categories/hotels/:category',
    RESTAURANTS_CATEGORY: '/categories/restaurants/:category',
  },
  SUBTYPES: {
    SHOPS: 'shops',
    SERVICES: 'services',
    HOTELS: 'hotels',
    RESTAURANTS: 'restaurants',
  },
  SURVEYS_AND_POLLS: '/surveys',
  GOOGLE: '/google',
  COLLECTING_REVIEWS: '/collecting-reviews',
  INCREASE_SALES: '/increase-sales',
  CLIENT_CONTACT: '/client-contact',
  SEARCH_RESULTS: '/search/:phrase',
  USER_REGULATIONS: '/user-regulations',
  BUSINESS_REGULATIONS: '/regulations',
  PRIVACY_POLICY: '/privacy-policy',
  CONTACT: '/contact',
  BLOG: 'https://blog.trustmate.io',
  PODCAST: 'https://platforms.trustmate.io/podcast',
  PARTNERS: '/partnership',
  PARTNERS_REGISTER: '/partners/register',
  PARTNERS_LOGIN: '/partners/login',
  REVIEWS: '/our-reviews',
  INTEGRATIONS: '/integrations',
  REVIEW_TRANSLATE: '/review-translate',
  WHY_US: '/why-us',
  SA_INFO: '/SA-info',
  BADGES: '/badges',
  MORE_SALES: '/more-sales',
  TRAFFIC: '/traffic',
  CROSSBORDER_SALES: '/crossborder-sales',
  PRODUCT_POLLS: '/product-polls',
  REVIEW_COLLECTION: '/review-collection',
  EU: '/projekty-ue',
  // Just for redirection purpuse, can be remove when clients stop using old QR product profiles
  PRODUCT_BUSINESS_CARD: '/company/:uuid/product/:productId',
  TEAM: '/our-team',
  HOTELS: '/hotels',
};

type I18nRouteType = {
  [key: string]: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
  };
};

export const I18N_ROUTES: I18nRouteType = {
  bg: {
    COMPANY_PROFILE: '/otzivi/:slug',
    REVIEWS: '/otzivi',
  },
  bs: {
    COMPANY_PROFILE: '/misljenja/:slug',
    REVIEWS: '/misljenja',
  },
  cs: {
    COMPANY_PROFILE: '/hodnoceni/:slug',
    REVIEWS: '/hodnoceni',
  },
  de: {
    COMPANY_PROFILE: '/bewertungen/:slug',
    REVIEWS: '/bewertungen',
  },
  el: {
    COMPANY_PROFILE: '/vathmologies/:slug',
    REVIEWS: '/vathmologies',
  },
  en: {
    COMPANY_PROFILE: '/reviews/:slug',
    REVIEWS: '/reviews',
  },
  es: {
    COMPANY_PROFILE: '/opiniones/:slug',
    REVIEWS: '/opiniones',
  },
  et: {
    COMPANY_PROFILE: '/arvustused/:slug',
    REVIEWS: '/arvustused',
  },
  fr: {
    COMPANY_PROFILE: '/avis/:slug',
    REVIEWS: '/avis',
  },
  hr: {
    COMPANY_PROFILE: '/recenzije/:slug',
    REVIEWS: '/recenzije',
  },
  hu: {
    COMPANY_PROFILE: '/ertekelesek/:slug',
    REVIEWS: '/ertekelesek',
  },
  it: {
    COMPANY_PROFILE: '/recensioni/:slug',
    REVIEWS: '/recensioni',
  },
  lt: {
    COMPANY_PROFILE: '/atsiliepimai/:slug',
    REVIEWS: '/atsiliepimai',
  },
  lv: {
    COMPANY_PROFILE: '/atsauksmes/:slug',
    REVIEWS: '/atsauksmes',
  },
  nl: {
    COMPANY_PROFILE: '/reviews-nl/:slug',
    REVIEWS: '/reviews-nl',
  },
  no: {
    COMPANY_PROFILE: '/anmeldelser/:slug',
    REVIEWS: '/anmeldelser',
  },
  pl: {
    COMPANY_PROFILE: '/opinie/:slug',
    REVIEWS: '/opinie',
  },
  pt: {
    COMPANY_PROFILE: '/opinioes/:slug',
    REVIEWS: '/opinioes',
  },
  ro: {
    COMPANY_PROFILE: '/recenzie/:slug',
    REVIEWS: '/recenzie',
  },
  ru: {
    COMPANY_PROFILE: '/otzyvy/:slug',
    REVIEWS: '/otzyvy',
  },
  sk: {
    COMPANY_PROFILE: '/hodnoteni/:slug',
    REVIEWS: '/hodnoteni',
  },
  sl: {
    COMPANY_PROFILE: '/mnenja-in-ocene/:slug',
    REVIEWS: '/mnenja-in-ocene',
  },
  sr: {
    COMPANY_PROFILE: '/recenzije-sr/:slug',
    REVIEWS: '/recenzije-sr',
  },
  sv: {
    COMPANY_PROFILE: '/omdomen/:slug',
    REVIEWS: '/omdomen',
  },
  uk: {
    COMPANY_PROFILE: '/vidhuky/:slug',
    REVIEWS: '/vidhuky',
  },
  tr: {
    COMPANY_PROFILE: '/yorumlar/:slug',
    REVIEWS: '/yorumlar',
  },
  da: {
    COMPANY_PROFILE: '/anmeldelser/:slug',
    REVIEWS: '/anmeldelser',
  },
  fi: {
    COMPANY_PROFILE: '/arvostelut/:slug',
    REVIEWS: '/arvostelut',
  },
};

export default ROUTES;
