
    import __i18nConfig from '@next-translate-root/i18n'
    import __appWithI18n from 'next-translate/appWithI18n'
    import { createGlobalStyle, ThemeProvider } from 'styled-components';
import 'react-multi-carousel/lib/styles.css';
import { GlobalStyles } from 'config/globalStyles';
import theme, { DARK_THEME, LIGHT_THEME } from 'config/theme';
import I18nProvider from 'next-translate/I18nProvider';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useDarkMode } from 'shared/hooks/useDarkMode';
import useTranslation from 'shared/hooks/useTranslation';
import badgesPageEN from '../locales/en/badgesPage.json';
import categoryPageEN from '../locales/en/categoryPage.json';
import collectingReviewsPageEN from '../locales/en/collectingReviewsPage.json';
import commonEN from '../locales/en/common.json';
import contactPageEN from '../locales/en/contactPage.json';
import findCompanyPageEN from '../locales/en/findCompanyPage.json';
import footerEN from '../locales/en/footer.json';
import googlePageEN from '../locales/en/googlePage.json';
import homePageEN from '../locales/en/homePage.json';
import hotelsPageEN from '../locales/en/hotelsPage.json';
import integrationsPageEN from '../locales/en/integrationsPage.json';
import mediationSystemPageEN from '../locales/en/mediationSystemPage.json';
import ourTeamPageEN from '../locales/en/ourTeamPage.json';
import partnersPageEN from '../locales/en/partnersPage.json';
import pricingPageEN from '../locales/en/pricingPage.json';
import productPageEN from '../locales/en/productPage.json';
import profilePageEN from '../locales/en/profilePage.json';
import referencesPageEN from '../locales/en/referencesPage.json';
import reviewCollectionPageEN from '../locales/en/reviewCollectionPage.json';
import reviewsPageEN from '../locales/en/reviewsPage.json';
import reviewTranslatePageEN from '../locales/en/reviewTranslatePage.json';
import surveysPageEN from '../locales/en/surveysPage.json';
import whyUsPageEN from '../locales/en/whyUsPage.json';
const GlobalStyle = createGlobalStyle `
  body {
    font-family: ${(props) => props.theme.FONTS.PRIMARY};
    color: ${(props) => props.theme.COLORS.PRIMARY};
  }
  
  button, a, input {
    font-family: ${(props) => props.theme.FONTS.PRIMARY};
  }
`;
const TRANSLATIONS = {
    commonEN,
    contactPageEN,
    footerEN,
    homePageEN,
    partnersPageEN,
    referencesPageEN,
    pricingPageEN,
    surveysPageEN,
    reviewsPageEN,
    mediationSystemPageEN,
    integrationsPageEN,
    googlePageEN,
    collectingReviewsPageEN,
    profilePageEN,
    categoryPageEN,
    reviewTranslatePageEN,
    whyUsPageEN,
    hotelsPageEN,
    badgesPageEN,
    productPageEN,
    findCompanyPageEN,
    reviewCollectionPageEN,
    ourTeamPageEN,
};
function CustomApp({ Component: AppComponent, pageProps }: AppProps): JSX.Element {
    const { t } = useTranslation('common');
    const { theme: themeMode } = useDarkMode();
    return (<I18nProvider namespaces={TRANSLATIONS}>
      <ThemeProvider theme={themeMode === 'light'
            ? { ...theme, THEME_MODE: { ...LIGHT_THEME } }
            : { ...theme, THEME_MODE: { ...DARK_THEME } }}>
        <GlobalStyles />
        <Head>
          <title>{t('titleTag')}</title>
          <meta name="description" content={t('metaDescription')}/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <style>{`
            html {
              font-size: 62.5%;
            }

            *,
            *::after,
            *::before {
              margin: 0;
              padding: 0;
              box-sizing: inherit;
            }

            body {
              box-sizing: border-box;
              line-height: 1.5;
            }
          `}</style>
        </Head>
        <GlobalStyle />
        <AppComponent {...pageProps}/>
      </ThemeProvider>
    </I18nProvider>);
}
const __Next_Translate__Page__1932fd5e765__ = CustomApp;

    export default __appWithI18n(__Next_Translate__Page__1932fd5e765__, {
      ...__i18nConfig,
      isLoader: true,
      skipInitialProps: true,
      loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
    });
  