import { DefaultTheme } from 'styled-components';

const PRIMARY = '#1d1d1d';
const SECONDARY_BLUE = '#1e1e1e';

export const COLORS = {
  PRIMARY,
  PRIMARY_RGB: '11, 86, 128',
  PRIMARY_LIGHTER: 'rgba(10, 10, 10, .5)',
  PRIMARY_DARKER: '#073651',
  SECONDARY_ASH: '#A1BECE',
  SECONDARY_BLUE,
  SECONDARY_BLUE_HOVER: 'rgba(29,113,184, .05)',
  SECONDARY_BLUE_FOCUS: 'rgba(29,113,184, .2)',
  SECONDARY: '#FF8730',
  SECONDARY_ORANGE: '#FF6B00',
  ASH: '#f1f1f1;',
  AZURE: '#2e2e2e',
  RED_LIGHTER: 'rgba(228, 6, 6, .5)',
  RED: '#E40606',
  PRIMARY_GRADIENT: `linear-gradient(180deg, ${SECONDARY_BLUE} 0%, ${PRIMARY} 100%)`,
  WHITE: '#FFFFFF',

  V2: {
    PRIMARY: '#000000',
    PRIMARY200: '#32435C',
    SECONDARY: '#FC8000',
    SECONDARY500: '#B84B1B',
    ASH: '#FEFEFE',
    GRAY200: '#CCCCCC',
    GRAY400: '#9A9EA6',
    GRAY500: '#969696',
    PRIMARY_FOCUS: '#08080833',
    GREEN200: '#2FCC59',
    WHITE: '#FFFFFF',
    BACKGROUND: '#030201',
  },
};

export const LIGHT_THEME = {
  TEXT: COLORS.PRIMARY,
  BACKGROUND: COLORS.WHITE,
  NAVBAR_BACKGROUND: COLORS.WHITE,
  NAVBAR_POSITION: 'fixed',
  FOOTER_SECONDARY: COLORS.V2.GRAY400,
  FOOTER_CAROUSEL_BACKGROUND: 'white',
  FOOTER_CAROUSEL_SHADOW: '#f7f7f7;',
};

export const DARK_THEME = {
  TEXT: COLORS.WHITE,
  BACKGROUND: '#030201',
  NAVBAR_BACKGROUND: 'rgba(0,0,0,0)',
  NAVBAR_POSITION: 'absolute',
  FOOTER_SECONDARY: COLORS.V2.GRAY200,
  FOOTER_CAROUSEL_BACKGROUND: '#111;',
  FOOTER_CAROUSEL_SHADOW: '#111;',
};

export const FONTS = {
  MAIN: '"Inter", sans-serif',
  PRIMARY: '"Montserrat", sans-serif',
  SECONDARY: '"Open Sans", sans-serif',
};

export const SHADOWS = {
  PRIMARY: '0 2px 10px rgba(126, 126, 126, 0.2)',
  PRIMARY_LIGHT: '0 1px 5px rgba(11, 86, 128, 0.2)',
  LIGHT: '0 2px 10px rgba(11, 86, 128, 0.06)',
  ORANGE: '0 2px 10px rgba(255, 107, 0, 0.2)',
  HOVER_ORANGE: '0 10px 40px -5px rgba(255, 107, 0, 0.2)',
  PRIMARY800: '#000000cc',
};

export const BREAKPOINT = {
  SM: '575px',
  MD: '767px',
  LG: '991px',
  XL: '1199px',
  XXL: '1599px',
};

export const DEVICE = {
  SM: '(min-width: 576px)',
  MD: '(min-width: 768px)',
  LG: '(min-width: 992px)',
  XL: '(min-width: 1200px)',
  XXL: '(min-width: 1600px)',
};

export const MEDIA = {
  SM: `@media ${DEVICE.SM}`,
  MD: `@media ${DEVICE.MD}`,
  LG: `@media ${DEVICE.LG}`,
  XL: `@media ${DEVICE.XL}`,
  XXL: `@media ${DEVICE.XXL}`,
};

export const BORDER_RADIUS = '4px';

const theme: DefaultTheme = {
  BORDER_RADIUS,
  COLORS,
  BREAKPOINT,
  DEVICE,
  FONTS,
  MEDIA,
  SHADOWS,
};

export default theme;
