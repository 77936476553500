import useTranslation from 'next-translate/useTranslation';

type FallbacksType = {
  [key: string]: string;
};

const fallbacks: FallbacksType = {
  common: 'commonEN',
  contactPage: 'contactPageEN',
  footer: 'footerEN',
  homePage: 'homePageEN',
  partnersPage: 'partnersPageEN',
  referencePage: 'referencePageEN',
  pricingPage: 'pricingPageEN',
  referencesPage: 'referencesPageEN',
  surveysPage: 'surveysPageEN',
  reviewsPage: 'reviewsPageEN',
  profilePage: 'profilePageEN',
  mediationSystemPage: 'mediationSystemPageEN',
  integrationsPage: 'integrationsPageEN',
  googlePage: 'googlePageEN',
  collectingReviewsPage: 'collectingReviewsPageEN',
  categoryPage: 'categoryPageEN',
  reviewTranslatePage: 'reviewTranslatePageEN',
  whyUsPage: 'whyUsPageEN',
  hotelsPage: 'hotelsPageEN',
  badgesPage: 'badgesPageEN',
  productPage: 'productPageEN',
  findCompanyPage: 'findCompanyPageEN',
  reviewCollectionPage: 'reviewCollectionPageEN',
  ourTeamPage: 'ourTeamPageEN',
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function useTranslationWithFallback(ns?: string | null) {
  const { t, lang } = useTranslation();

  if (ns) {
    return {
      t: (key: string, optionalNs?: string) => {
        let currentText;
        let fallbackText;
        if (optionalNs) {
          currentText = `${optionalNs}:${key}`;
          fallbackText = `${fallbacks[optionalNs]}:${key}`;
        } else {
          currentText = `${ns}:${key}`;
          fallbackText = `${fallbacks[ns]}:${key}`;
        }

        const currentTranslation = t(currentText);
        if (currentTranslation.includes(ns) || currentTranslation.includes(optionalNs as string))
          return t(fallbackText);
        return currentTranslation;
      },
      lang,
    };
  }
  return {
    t: (key: string) => {
      const currentText = `${ns}:${key}`;
      const currentTranslation = t(currentText);
      return currentTranslation;
    },
    lang,
  };
}
